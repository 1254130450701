import { Component} from '@angular/core';
import * as mixpanel from "mixpanel-browser";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(){
    this.init()
  }

  init(): void {
    mixpanel.init("65b00f14201ea910a6cb9e6ff2eb7083");
    
  }

}
