import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LinkedinProfileComponent } from "../app-linkedin-profile/app-linkedin-profile.component";
import { CommonService } from "../common.service";
import { AgencyService } from "./agency.service";

export interface PeriodicElement {
  name: string;
  position: string;
  weight: string;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: "Janice Feest I",
    name: "hulda+yamaguchi453@web.com",
    weight: "Feb 18, 2021",
    symbol: "Active",
  },
];

@Component({
  selector: "app-agency",
  templateUrl: "agency.component.html",
  styleUrls: ["agency.component.css"],
})
export class AgencyComponent implements OnInit {
  companySection = false;
  agencystepOne: boolean = true;
  agencystepTwo: boolean = false;
  agencystepOneCompleted: boolean = false;
  agencystepTwoCompleted: boolean = false;
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  stepOneCompleted: boolean = false;
  stepTwoCompleted: boolean = false;
  stepThreeCompleted: boolean = false;
  button_spinner = false;
  allCountryList = [];
  heading = "Agency plan";
  discription =
    "Please let us know your agency’s name to proceed with setting up your account";

  agencyForm = new FormGroup({
    agency_name: new FormControl("", Validators.required),
  });
  companyForm = new FormGroup({
    company_name: new FormControl("", Validators.required),
    email: new FormControl("", Validators.required),
  });
  // reposeComplete

  linkedinreqCompleted: Boolean = true;
  billingResCompleted:Boolean = true;
  linkedinOtpreqCompleted: Boolean = true;
  companyresCompleted:Boolean = true;
  agencyresCompleted: Boolean = true

  // linkedin veriable

  linkedinConnectionData;
  linkedinStatus;
  plantype;
  headerHeading;
  showpopMsg = false;
  showverificationpopMsg = false;
  showorhideModal;
  showorhideProfileModal;
  sub_type = "Business";
  companyId;
  billingId;
  linkedinId = null;
  done_disable = true;
  first_id;
  actor_urn;
  billingForm = new FormGroup({
    // f_name: new FormControl("", Validators.required),
    // l_name: new FormControl("", Validators.required),
    line1: new FormControl("", Validators.required),
    line2: new FormControl(""),
    city: new FormControl("", Validators.required),
    state: new FormControl("", Validators.required),
    country: new FormControl("", Validators.required),
    postal_code: new FormControl("", Validators.required),
  });

  linkedInForm = new FormGroup({
    userid: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });
  linkedInotpForm = new FormGroup({
    otp: new FormControl("", Validators.required),
  });

 

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "name",
    "email",
    "created_at",
    "status",
    "edit_btn",
  ];
  
  @ViewChild(LinkedinProfileComponent)
  linkedInProfileComponent: LinkedinProfileComponent;

  editCompany = false;

  constructor(
    private agency: AgencyService,
    private common: CommonService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.linkedinId = localStorage.getItem('linkedinId')
    this.getAgencyDetails();
    this.getLinkdincredList();
    this.getcountryList();
    // this.companySection = true
    this.agency.linkdinId.subscribe((res) => {
      this.linkedinId = res;
      // this.editCompanyAccount(this.linkedinId);
      this.stepOne = true;
      // this.editCompany = true
      this.companySection = true;
      this.heading = "Company details";
      this.discription =
        "Enter the details of the company that you want to manage in Triggify";
    });
    this.agency.editCompanyDetails.subscribe((res) => {
      this.heading = "Company details";
      this.discription =
        "Enter the details of the company that you want to manage in Triggify";
      this.editCompany = res;
      this.companySection = res;
    });
    this.agency.companyformData$.subscribe((data) => {
      if (data) {
        this.companyForm.patchValue(data);
      }
    });
    this.agency.billingformData$.subscribe((data) => {
      if (data) {
        this.billingForm.patchValue(data);
      }
    });
    this.agency.linkedinformData$.subscribe((data) => {
      if (data) {
        this.linkedInForm.patchValue({
          userid: data.userid,
        });
      }
    });
  }

  agencySubmit() {
    this.agencyresCompleted = false
    this.agency.agencyUpdate(this.agencyForm.value).subscribe((res) => {
      this.agencyresCompleted = true
      if (res.status == 200) {
        this.toastr.success("Added successfully.", "", {
          closeButton: true,
          positionClass: "toast-bottom-center",
        });
        this.heading = "Connect accounts";
        this.discription =
          "Add your portfolio companies and connect a LinkedIn account for each company";
        this.agencystepOne = false;
        this.agencystepOneCompleted = true;
        this.agencystepTwo = true;
      } else {
        this.toastr.error("SomeThing went wrong try again", "", {
          closeButton: true,
          positionClass: "toast-bottom-center",
        });
      }
    });
  }

  getAgencyDetails() {
    this.agency.agencydetails().subscribe((res) => {
      if (res.status == 200) {
        if (res.body.agency_name == null) {
          this.agencystepOne = true;
          this.agencystepOneCompleted = false;
          this.agencystepTwo = false;
          this.heading = "Agency plan";
          this.discription =
            "Please let us know your agency’s name to proceed with setting up your account";
        } else {
          if (this.editCompany) {
          } else {
            this.heading = "Connect accounts";
            this.discription =
              "Add your portfolio companies and connect a LinkedIn account for each company";
          }
          this.agencystepOne = false;
          this.agencystepOneCompleted = true;
          this.agencystepTwo = true;
        }
        // this.agencystepOne = false;
        // this.agencystepOneCompleted = true;
        // this.agencystepTwo = true;
      }
    });
  }

  getLinkdincredList() {
    this.agency.llinkedincredList().subscribe((res) => {
      this.dataSource.data = res.body;
      if (res.body.length) {
        this.done_disable = false;
        this.first_id = res.body[0].id;
        localStorage.setItem("linkedinId", res.body[0].id);
      } else {
        this.done_disable = true;
      }
    });
  }

  addnewCompany() {
    this.stepOne = true;
    this.companySection = true;
    this.heading = "Company details";
    this.discription =
      "Enter the details of the company that you want to manage in Triggify";
    this.linkedinId = null;
    this.agencyForm.reset();
    this.billingForm.reset();
    this.companyForm.reset();
  }

  companyNameAdd() {
    this.companyresCompleted = false
    if (this.linkedinId) {
      this.agency
        .updatecompanyresponse(this.companyForm.value, this.linkedinId)
        .subscribe((res) => {
          this.companyresCompleted = true
          this.companyId = res.body.id;
          this.heading = "Location details";
          this.discription =
            "This location must be the same as the location from where you normally access the LinkedIn account that is an admin for this companies LinkedIn page. We will provide you with a free proxy to protect your IP address.";
        });
    } else {
      this.agency.createCompany(this.companyForm.value).subscribe((res) => {
        this.companyId = res.body.message.id;
        this.companyresCompleted = true
        this.heading = "Location details";
        this.discription =
          "This location must be the same as the location from where you normally access the LinkedIn account that is an admin for this companies LinkedIn page. We will provide you with a free proxy to protect your IP address.";
      });
    }

    this.stepOne = false;
    this.stepTwo = true;
    this.stepOneCompleted = true;
  }

  getcountryList() {
    this.common.getAllCountry().subscribe((res) => {
      //   console.log(res, "countrylist");
      if (res.status == 200) {
        this.allCountryList = res.body.message.country_code;
      }
    });
  }

  createBillDetails() {
    this.button_spinner = true;
    this.billingResCompleted = false
    // console.log(this.billingForm.value, "billing Form");
    if (this.linkedinId) {
      this.common
        .onUpdateBill(this.billingForm.value, this.linkedinId)
        .subscribe((res) => {
          this.button_spinner = false;
          this.billingResCompleted = true
          if (res.status == 200) {
            this.toastr.success("Added successfully.", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            this.heading = "Connect LinkedIn";
            this.discription =
              "Connect a LinkedIn account that is an admin for the companies LinkedIn page";
            this.billingId = res.body.id;
            // console.log(this.linkedinStatus, "status");
            if (false) {
            } else {
              this.stepOne = false;
              this.stepTwo = false;
              this.stepThree = true;
              this.stepOneCompleted = true;
              this.stepTwoCompleted = true;
              this.heading = "Connect LinkedIn";
              this.discription =
                "Connect a LinkedIn account that is an admin for the companies LinkedIn page";
            }
          } else {
            this.toastr.error(res.error.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          }
        });
    } else {
      this.common.onCreateBill(this.billingForm.value).subscribe((res) => {
        this.button_spinner = false;
        this.billingResCompleted = true
        if (res.status == 200) {
          this.toastr.success("Added successfully.", "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
          this.billingId = res.body.id;
          // console.log(this.linkedinStatus, "status");
          if (false) {
          } else {
            this.stepOne = false;
            this.stepTwo = false;
            this.stepThree = true;
            this.stepOneCompleted = true;
            this.stepTwoCompleted = true;
            this.heading = "Connect LinkedIn";
            this.discription =
              "Connect a LinkedIn account that is an admin for the companies LinkedIn page";
          }
        } else {
          this.toastr.error(res.error.message, "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
        }
      });
    }
  }

  // linkdin connect step

  connectToLinkedin() {
    this.showpopMsg = true;
    this.button_spinner = true;
    this.linkedinreqCompleted = false
    let obj = {
      billing_id: this.billingId,
      response_email_id: this.companyId,
    };
    let bodyData = { ...obj, ...this.linkedInForm.value };
    if (this.linkedinId == null) {
      this.common.onConnectToLinkedin(bodyData).subscribe((res) => {
        this.linkedinConnectionData = res.body;

        // this.linkedinId = res.body.linkedin
        this.button_spinner = false;
        this.linkedinreqCompleted = true
        if (res.status == 200) {
          this.linkedinId = res.body.linkedin;
          if (res.body.message == "added") {
            this.toastr.success(res.body.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            this.linkedinId = res.body.linkedin;
            // analytics.track("Connected LinkedIn in onboarding flow", {});
            // this.mixPanel.track("Connected LinkedIn successfully", {});
            this.linkedInProfileComponent.getLinkedinProfile(res.body.linkedin);
            this.showpopMsg = false;
          } else if (
            res.body.message ==
            "Incorrect Username or Password, Please check it"
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "We were not able to connect your account. Please double-check your credentials and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes(
              "Failed to refresh IP or reached max retry limit"
            )
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "Connection failed: LinkedIn is giving you a CAPTCHA because you logged in/out too many times. Please try again in 24 hours or use a different LinkedIn account.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes(
              "phone number is required, please add phone number on your account"
            )
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "Please add a phone number to your LinkedIn account and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (res.body.message.includes("Your account is restricted")) {
            this.showpopMsg = false;
            this.toastr.error(
              "Your account is restricted. Please activate your LinkedIn account and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes("failed to generate captcha token")
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "Oops! We couldn't generate the captcha token. Please try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else {
            this.showpopMsg = false;
            this.toastr.success(res.body.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            this.spinner.hide();
            this.showorhideModal = true;
          }
        } else {
          this.linkedinId = res.error.linkedin;
          this.showpopMsg = false;
          if (
            res.error.message ==
            "'userid' - ErrorDetail(string='linkedin credentials with this userid already exists.', code='unique')"
          ) {
            this.toastr.error(
              "Your LinkedIn profile is already connected to an existing Triggify account. Please login to your existing account.",
              "",
              {
                closeButton: true,
                extendedTimeOut: 0,
                timeOut: 0,
                tapToDismiss: false,
                positionClass: "toast-bottom-center",
              }
            );
          } else if (
            res.error.message ==
            "Incorrect Username or Password, Please check it"
          ) {
            this.toastr.error(
              "We were not able to connect your account. Please double check your credentials and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          } else if (
            res.error.message ==
            "previous login script is running, please try again after 5 minutes"
          ) {
            this.toastr.error(
              "previous login script is running, please try again after 5 minutes",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          } else if (res.error.message == "failed to login") {
            this.toastr.error("failed to login.", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          } else if (
            res.error.message.includes("ERR_TUNNEL_CONNECTION_FAILED")
          ) {
            this.toastr.error("Proxy Failed Please Try after sometime", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          } else if (
            res.error.message.includes("Agency user linkedin account limit")
          ) {
            this.toastr.error(res.error.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          } else {
            this.toastr.error(
              "Something Went wrong Please Try again or try after sometime",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          }
          this.showorhideModal = false;
          this.linkedinConnectionStatus();
        }
      });
    } else {
      this.common
        .onUpdateToLinkedinId(bodyData, this.linkedinId)
        .subscribe((res) => {
          this.linkedinConnectionData = res.body;

          this.button_spinner = false;
          this.linkedinreqCompleted = true
          if (res.status == 200) {
            if (res.body.message == "added") {
              this.toastr.success(res.body.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
              // analytics.track("Connected LinkedIn in onboarding flow", {});
              // this.mixPanel.track("Connected LinkedIn successfully", {});
              this.linkedInProfileComponent.getLinkedinProfile(this.linkedinId);
              this.showpopMsg = false;
            } else if (
              res.body.message ==
              "Incorrect Username or Password, Please check it"
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "We were not able to connect your account. Please double-check your credentials and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes(
                "Failed to refresh IP or reached max retry limit"
              )
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Connection failed: LinkedIn is giving you a CAPTCHA because you logged in/out too many times. Please try again in 24 hours or use a different LinkedIn account.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes(
                "phone number is required, please add phone number on your account"
              )
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Please add a phone number to your LinkedIn account and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes("Your account is restricted")
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Your account is restricted. Please activate your LinkedIn account and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes("failed to generate captcha token")
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Oops! We couldn't generate the captcha token. Please try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else {
              this.showpopMsg = false;
              this.toastr.success(res.body.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
              this.spinner.hide();
              this.showorhideModal = true;
            }
          } else {
            this.showpopMsg = false;
            if (
              res.error.message ==
              "'userid' - ErrorDetail(string='linkedin credentials with this userid already exists.', code='unique')"
            ) {
              this.toastr.error(
                "Your LinkedIn profile is already connected to an existing Triggify account. Please login to your existing account.",
                "",
                {
                  closeButton: true,
                  extendedTimeOut: 0,
                  timeOut: 0,
                  tapToDismiss: false,
                  positionClass: "toast-bottom-center",
                }
              );
            } else if (
              res.error.message ==
              "previous login script is running, please try again after 5 minutes"
            ) {
              this.toastr.error(
                "previous login script is running, please try again after 5 minutes",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
            } else if (
              res.error.message ==
              "Incorrect Username or Password, Please check it"
            ) {
              this.toastr.error(
                "We were not able to connect your account. Please double check your credentials and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
            } else if (res.error.message == "failed to login") {
              this.toastr.error("failed to login.", "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            } else if (
              res.error.message.includes("ERR_TUNNEL_CONNECTION_FAILED")
            ) {
              this.toastr.error("Proxy Failed Please Try after sometime", "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            } else if (
              res.error.message.includes("Agency user linkedin account limit")
            ) {
              this.toastr.error(res.error.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            } else {
              this.toastr.error(
                "Something Went wrong Please Try again or try after sometime",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
            }
            this.showorhideModal = false;
            this.linkedinConnectionStatus();
          }
        });
    }
  }

  verifyLinkedin() {
    this.button_spinner = true;
    this.linkedinOtpreqCompleted = false
    this.showverificationpopMsg = true;
    // console.log(this.linkedInotpForm.value);
    // console.log(this.sub_type);
    delete this.linkedinConnectionData.message;
    // console.log(this.linkedinConnectionData, "connection data");
    this.linkedinConnectionData["pin"] = this.linkedInotpForm.value.otp;
    this.linkedinConnectionData["linkedin"] = this.linkedinId;
    // console.log(this.linkedinConnectionData, " afterconnection data");
    this.common
      .linkedinPinVerify(this.linkedinConnectionData)
      .subscribe((res) => {
        // console.log(res);
        this.button_spinner = false;
        this.linkedinOtpreqCompleted = true
        if (res.status == 200) {
          this.showorhideModal = false;
          this.showverificationpopMsg = false;
          // this.mixPanel.track("Connected LinkedIn successfully", {});
          // analytics.track("Connected LinkedIn in onboarding flow", {});
          this.linkedInProfileComponent.getLinkedinProfile(this.linkedinId);
        } else {
          this.toastr.error(
            "We were not able to connect. You most likely have entered the wrong verification code. Please try again.",
            "",
            {
              closeButton: true,
              positionClass: "toast-bottom-center",
            }
          );
        }
      });
  }


  linkedinConnectionStatus() {
    this.common.getLinkedinProfile(this.linkedinId).subscribe((res) => {
      if (res.body.cookie == null) {
        this.linkedinStatus = false;
      } else {
        this.linkedinStatus = true;
      }
    });
  }


  editCompanyAccount(id) {
    this.linkedinId = id;
    this.editCompany = true;
    this.stepOne = true;
    this.companySection = true;
    this.heading = "Company details";
    this.discription =
      "Enter the details of the company that you want to manage in Triggify";
    this.agency.updateFormData(id);
  }

  billingDetails(id) {
    this.common.getBillingDetails(id).subscribe((res) => {
      // console.log(res, "biiling details resonse");
      this.spinner.hide();

      if (res.status == 200) {
        if (res.body.message.billing_details.name == null) {
          // let array = res.body.message.billing_details.name.split(" ");
          // var theRemovedElement = array.shift(); // theRemovedElement == 1
          // console.log(array.join(" "),"split and joint",theRemovedElement); // [2, 3, 4]
          this.billingForm.setValue({
            // f_name: res.body.message.billing_details.name,
            // l_name: res.body.message.billing_details.name,
            line1: res.body.message.billing_details.line1,
            line2: res.body.message.billing_details.line2,
            city: res.body.message.billing_details.city,
            country: res.body.message.billing_details.country,
            postal_code: res.body.message.billing_details.postal_code,
            state: res.body.message.billing_details.state,
          });
        } else {
          let array = res.body.message.billing_details.name.split(" ");
          // console.log(array, "array");
          var theRemovedElement = array.shift(); // theRemovedElement == 1
          // console.log(array.join(" "),"split and joint",theRemovedElement); // [2, 3, 4]
          this.billingForm.setValue({
            // f_name: theRemovedElement,
            // l_name: array.join(" "),
            line1: res.body.message.billing_details.line1,
            line2: res.body.message.billing_details.line2,
            city: res.body.message.billing_details.city,
            country: res.body.message.billing_details.country,
            postal_code: res.body.message.billing_details.postal_code,
            state: res.body.message.billing_details.state,
          });
        }
      } else {
      }
    });
  }

  done() {
    this.router.navigate(["agency-dashbaord"]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  hidepopup() {
    this.showorhideModal = false;
  }

  hideLinpopup() {
    this.showorhideProfileModal = false;
  }

   // handle successfully seleted linkedin profile

   handleSuccess() {
    // Handle the success response here
    this.companySection = false;
    this.stepOne = true;
    this.stepTwo = false;
    this.stepThree = false;
    this.stepOneCompleted = false;
    this.stepTwoCompleted = false;
    this.stepThreeCompleted = false;
    this.agencystepOne = false;
    this.agencystepOneCompleted = true;
    this.agencystepTwo = true;
    this.getLinkdincredList();
    this.showorhideProfileModal = false;
    this.linkedinId = null;
  }
}
